<!--
 * @Author: Libra
 * @Date: 2021-10-27 15:09:40
 * @LastEditTime: 2021-10-28 10:41:40
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/components/privateRule.vue
-->
<template>
  <div class="rule-container">
    <p class="p3">
      更新日期：2021-10-12<span
        class="Apple-converted-space"
        style="margin-right: 10px"
      >
      </span
      >生效日期：2021-10-12<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p4">&nbsp;</p>
    <p class="p5">
      国考云在线考试面试平台（以下简称国考云）是由北京国聘考试技术有限公司（以下简称&ldquo;我们&rdquo;）提供服务的智能在线考试面试平台。注册地为北京市东城区青龙胡同甲1号、3号2幢2层203-109室。考试面试运营过程中也会与关联公司及您应聘或参加相关考试面试的企业、机构联合运营。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p4">&nbsp;</p>
    <p class="p3">
      1. 我们收集的信息<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p5">
      基于国考云考试服务是向您提供相关考试和相关企业的面试，在考试或面试中需要您提供一些您的个人信息进行核实、注册和申请相关的考试或面试。因此，在您使用国考云考试服务的过程中，我们会在取得您明确的同意的前提下，按照如下方式收集您在使用国考云考试服务时，因使用服务而需要的相关的信息，用以向您提供服务、优化我们的服务以及保障您的账户安全：
    </p>
    <p class="p5">
      -您应聘或参加相关考试面试的企业、机构委托国考云获取您的手机号、邮箱、身份证、应聘岗位、毕业学校、专业等信息，信息仅应用于考试面试运营中进行身份核实。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      -如考试面试将使用视频音频的情况下，在您授权同意后，我们会访问您的麦克风、摄像头、相册或内存卡，为您提供视频拍摄、声音录制、视频音频读取等功能。视频音频的采集将用于您应聘或参加相关考试面试的企业、机构进行成绩判断和行为判定。您在进行人脸识别等相关功能时，我们会在征得您同意后收集您的手机号和身份证号信息用于实名认证。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      -为保障您正常使用我们的服务，标识您的用户身份，维护我们服务的正常运行，改进及优化我们的服务体验、保障您的帐号安全并进行安全风控，我们会收集您的设备型号、操作系统、IMEI、AndroidID、MAC地址、登陆IP地址、软件版本号、接入网络的方式、类型和状态、网络质量数据、操作日志、服务日志信息等日志信息，这类信息是为提供服务必须收集的基础信息。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      -为了更好地向您提供服务、改善使用体验，我们可对您登录的国考云客户端、网页浏览器、手机中的相关操作等信息进行使用。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      -我们会在征得您的同意后，收集您的网络地址、GPS等精确地理位置信息，用于向您应聘或参加相关考试的企业、机构展示您的位置信息以及保障您的帐号安全。精确地理位置信息属于敏感信息，拒绝提供该信息只会导致您无法全面使用我们提供的相关服务，但不影响您正常使用国考云提供的其他服务。我们会以加密的方式存储保护您的信息。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      -<strong>我们或我们的第三方合作伙伴可能通过cookie、标识符及相关技术收集您的信息，包括您的设备信息、浏览信息、点击信息，并将该等信息储存为日志信息，为您提供个性化的用户体验、保障服务安全。您可以通过浏览器设置拒绝或管理cookie、标识符或相关技术的使用</strong>。同时，为保障考试面试的稳定运行或实现相关功能，我们可能会接入由第三方提供的软件开发包（SDK）实现前述目的。
    </p>
    <p class="p5">
      -我们同时提供拍照美颜功能，，如您使用该功能上传或实时拍摄面部像素信息，我们的系统会进行实时智能识别，在识别过程中会实时收集您面部的像素信息，以为您提供更好的美颜效果，在此过程中我们不会存储您面部的像素信息，也不会将您的面部的像素信息用于其他用途。
    </p>
    <p class="p5">
      另外，根据相关法律法规及国家标准，以下情形中，我们可能会收集、使用您的相关个人信息无需征求您的授权同意：<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      （一）与国家安全、国防安全等国家利益直接相关的；与公共安全、公共卫生、公共知情等重大公共利益直接相关的；
    </p>
    <p class="p5">
      （二）为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；
    </p>
    <p class="p5">（三）为履行法定职责或者法定义务所必需；</p>
    <p class="p5">
      （四）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
    </p>
    <p class="p5">
      （五）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
    </p>
    <p class="p5">
      （六）依照本法规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；
    </p>
    <p class="p5">（七）法律、行政法规规定的其他情形。</p>
    <p class="p5">
      请您理解，我们向您提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明中且收集了您的信息，我们会通过页面提示、交互流程、网站公告等方式另行向您说明信息收集的内容、范围和目的，以征得您的同意。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      请您注意，目前我们不会主动从北京国聘考试技术有限公司（除您应聘或参加相关考试面试的企业、机构）外的第三方获取您的个人信息。如未来为业务发展需要从第三方间接获取您的个人信息，我们会在获取前要求第三方告知您，我公司的名称或者姓名、联系方式、处理目的、处理方式和个人信息的种类，并要求第三方取得您的明确同意。我们将向您告知个人信息的来源、类型并将按照第三方告知您我们的处理目的、处理方式及使用范围处理您的个人信息。如我们开展业务需进行的个人信息处理活动超出您原本向第三方提供个人信息时的授权同意范围，我们将在处理您该等个人信息前征得您的授权同意；此外，我们也将会严格遵守相关法律法规的规定，并要求第三方保障其提供的信息的合法性。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p4">&nbsp;</p>
    <p class="p3">
      2. 信息的存储<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p3">
      2.1 信息存储的方式和期限<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p5">
      -我们会通过安全的方式存储您的信息，包括本地存储（例如利用APP进行数据缓存）、数据库和服务器日志。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      -一般情况下，我们只会在为实现服务目的所必需的时间内或法律法规规定的条件下存储您的个人信息。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p3">
      2.2 信息存储的地域<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p5">
      -我们会按照法律法规规定，将境内收集的用户个人信息存储于中国境内。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      -目前我们不会跨境传输或存储您的个人信息。将来如需跨境传输或存储的，我们会向您告知信息出境的目的、接收方、安全保证措施和安全风险，并征得您的同意，同时按照国家法律法规的要去进行认证或实施相应的措施。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p3">
      2.3 产品或服务停止运营时的通知<span
class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      -当我们的产品或服务发生停止运营的情况时，我们将以推送通知、公告等形式通知您，并在合理期限内删除您的个人信息或进行匿名化处理，法律法规另有规定的除外。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p4">&nbsp;</p>
    <p class="p3">
      3. 信息安全<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p3">
      3.1 安全保护措施<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p5">
      -我们努力为用户的信息安全提供保障，以防止信息的泄露、丢失、不当使用、未经授权访问和披露等。我们使用多方位的安全保护措施，以确保用户的个人信息保护处于合理的安全水平，包括技术保护手段、管理制度控制、安全体系保障等诸多方面。此外，我们还通过了国家网络安全等级保护（三级）的测评和备案。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      -我们采用业界领先的技术保护措施。我们使用的技术手段包括但不限于防火墙、加密（例如SSL）、去标识化或匿名化处理、访问控制措施等。此外，我们还会不断加强安装在您设备端的软件的安全能力。例如，我们会在完成部分信息加密工作，以巩固安全传输；我们会了解您设备安装的应用信息和运行的进程信息，以预防病毒、木马等恶意程序。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      -我们建立了保障个人信息安全专门的管理制度、流程和组织。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务并进行审计，违反义务的人员会根据规定进行处罚。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      -我们也会审查该管理制度、流程和组织，以防未经授权的人员擅自访问、使用或披露用户的信息。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      -我们建议您在使用产品和服务时充分注意对个人信息的保护，我们也会提供多种安全功能来协助您保护自己的个人信息安全。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      -互联网并非绝对安全的环境，而且电子邮件、即时通讯等交流方式并未加密，我们强烈建议您不要通过此类方式发送个人或企业信息，请使用复杂密码，协助我们保证您的账号安全。
    </p>
    <p class="p3">
      3.2 安全事件处置措施<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p5">
      -若发生个人信息泄露、损毁、丢失等安全事件，我们会启动应急预案，阻止安全事件扩大。安全事件发生后，我们会及时以推送通知、邮件等形式告知您安全事件的基本情况、我们即将或已经采取的处置措施和补救措施，以及我们对您的应对建议。如果难以实现逐一告知，我们将通过公告等方式发布警示。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      -在不幸发生个人或企业信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人或企业信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人或企业信息安全事件的处置情况。
    </p>
    <p class="p5">
      -当您发现您的信息存在泄露的风险，您应第一时间通知我们，因您延迟通知给您带来的损失，我们不承担任何法律责任。
    </p>
    <p class="p4">&nbsp;</p>
    <p class="p3">
      4. 我们如何使用信息<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p5">
      我们严格遵守法律法规的规定以及与用户的约定，按照本隐私保护指引所述使用收集的信息，以向您提供更为优质的服务。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p3">
      4.1 信息使用规则<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p5">
      我们会按照如下规则使用收集的信息：<span
class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      -我们会根据我们收集的信息向您提供各项功能与服务，包括考试作答功能、视频面试服务、视频监考功能等；信息采集将用于您应聘或参加相关考试面试的企业、机构进行成绩判断和行为判定。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      -我们会根据您使用国考云的情况、故障信息、性能信息等分析我们产品的运行情况，以确保服务的安全性，并优化我们的产品，提高我们的服务质量。我们不会将我们存储在分析软件中的信息与您提供的个人身份信息相结合。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p3">
      4.2 定向推送服务的规则<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p5">
      我们会按照如下规则进行定向推送，并向您提供相应的退出机制：<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      -我们会根据您应聘或参加相关考试面试的企业、机构主动提供给我们的信息中，通过短信、邮件及电话联系方式通知您考试面试的具体信息。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      -您可以通过第10项&ldquo;联系我们&rdquo;中的联系方式，联系我们要求不继续推送服务。我们也在每次推送的信息中附有供您拒绝继续推送的选项，以便于您随时拒绝推送。
    </p>
    <p class="p3">
      4.3 告知变动目的后征得同意的方式<span
class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      我们将会在本隐私保护指引所涵盖的用途内使用收集的信息。如我们因为服务的需要使用您的个人信息，将超出与收集时所告知的目的及具有直接或合理关联的范围，我们将在使用您的个人信息前，再次向您告知并征得您的明示同意。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p3">
      5. 对外提供<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p5">
      目前，我们不会主动共享或转让您的个人信息至第三方，如存在其他共享或转让您的个人信息或您需要我们将您的个人信息共享或转让至第三方情形时，我们会向您告知第三方的名称或者姓名、联系方式、处理目的、处理方式和个人信息的种类，并取得您的明确同意，同时亦要求第三方直接或确认第三方征得到您对上述行为的明示同意。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      我们不会向合作伙伴分享可用于识别您个人身份的信息（例如您的姓名或电子邮件地址），除非您明确授权。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      我们不会对外公开披露所收集的个人信息，如必须公开披露时，我们会向您告知此次公开披露的目的、披露信息的类型及可能涉及的敏感信息，并征得您的明示同意。随着我们业务的持续发展，我们有可能进行合并、收购、资产转让等交易，如可能发生我们的服务由其他主体承接时，我们将告知您接收方的名称或者姓名和联系方式，并按照法律法规及不低于本隐私保护指引所要求的标准继续保护或要求新的控制者继续保护您的个人信息，并按照本政策的授权和使用目的，方式、范围处理您的个人信息。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p3">
      另外，根据相关法律法规及国家标准，以下情形中，我们可能会共享、转让、公开披露个人信息无需事先征得您的授权同意：<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p5">
      （一）与国家安全、国防安全等国家利益直接相关的；与公共安全、公共卫生、公共知情等重大公共利益直接相关的；
    </p>
    <p class="p5">
      （二）为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；
    </p>
    <p class="p5">（三）为履行法定职责或者法定义务所必需；</p>
    <p class="p5">
      （四）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
    </p>
    <p class="p5">
      （五）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
    </p>
    <p class="p5">
      （六）依照本法规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；
    </p>
    <p class="p5">（七）法律、行政法规规定的其他情形。</p>
    <p class="p4">&nbsp;</p>
    <p class="p3">
      6. 您的权利<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p5">
      在您使用国考云产品期间，为了让您更便捷地删除、更正您的个人信息，同时保障您撤回个人信息使用的同意和注销账户的权利，您可以拨打我们的客户服务或者直接给我们发送邮件，向我们申请删除您的个人信息，我们将在15个工作日内协助您完成删除。在删除个人信息之后，我们将停止那些需要您的个人信息方能向您提供的产品和服务，并依据法律法规的要求做出相应处理，您已经通过国考云向您应聘或参加相关考试面试的企业、机构发送的个人信息，我们无法保证删除，请您及时联系您应聘或参加相关考试面试的企业、机构。
    </p>
    <p class="p5">在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
    <p class="p5">（一）为履行法定职责或者法定义务所必需；</p>
    <p class="p5">
      （二）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
    </p>
    <p class="p5">
      （三）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
    </p>
    <p class="p5">
      （四）依照本法规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；
    </p>
    <p class="p5">（五）法律、行政法规规定的其他情形。</p>
    <p class="p3">7. 变更<span class="Apple-converted-space">&nbsp;</span></p>
    <p class="p5">
      我们会适时对本隐私保护指引进行修订。当隐私保护指引的条款发生变更时，我们会在版本更新时以通知或公告等方式向您提示变更后的隐私保护指引，并向您说明生效日期。请您届时仔细阅读变更后的隐私保护指引内容，如您希望继续使用国考云，则需再次同意我们修改后的隐私保护规则和条款。
    </p>
    <p class="p4">&nbsp;</p>
    <p class="p3">
      8. 未成年人保护<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p5">
      我们非常重视对未成年人信息的保护。基于我们的产品和服务的特点，我们产品和服务主要面向成年人，不向16周岁以下的未成年人提供服务。若您是已满16周岁不满18周岁的未成年人，建议您请您的监护人仔细阅读本隐私条款，并应确保已征得您的监护人同意的前提下使用我们的产品和服务并向我们提供您的信息。
    </p>
    <p class="p5">
      -如您的监护人不同意您按照本隐私条款使用我们的产品和服务并向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。
    </p>
    <p class="p5">
      -如果监护人发现我们对您所监护的未成年人的信息处理有任何疑问，请通过本隐私条款公布的联系方式及时联系我们。我们将根据国家相关法律法规及本隐私条款的规定重点保护未成年人信息的保密性及安全性。
    </p>
    <p class="p5">
      -如您为未成年人的父母或监护人，当您对所监护的未成年人的信息处理存在疑问时，请通过客服热线联系我们。
    </p>
    <p class="p4">&nbsp;</p>
    <p class="p3">9. 其他<span class="Apple-converted-space">&nbsp;</span></p>
    <p class="p5">
      国投人力《隐私政策》是我们统一适用的一般性隐私条款，其中所规定的用户权利及信息安全保障措施均适用于国考云用户。如国投人力《隐私政策》与本隐私保护指引存在不一致或矛盾之处，请以本隐私保护指引为准。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p4">&nbsp;</p>
    <p class="p3">
      10. 联系我们<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p5">
      当您有其他的投诉、建议、未成年人个人信息相关问题或需要删除个人信息时，请通过以下方式与我们联系。
    </p>
    <p class="p5">公司名称：北京国聘考试技术有限公司</p>
    <p class="p5">
      通信地址：北京市西城区阜外大街1号四川大厦东塔17层1706 国考云（收）
    </p>
    <p class="p5">联系电话：010-53397822</p>
    <p class="p5">联系邮箱：service@iguokao.com</p>
    <p class="p5">
      我们将尽快审核所涉问题，并在验证您的用户身份后的十五天内予以回复。<span
        class="Apple-converted-space"
        >&nbsp;</span
      >
    </p>
    <p class="p4">&nbsp;</p>
    <p class="p6">北京国聘考试技术有限公司</p>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
